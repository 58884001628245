import _ from "lodash";
import { useContext, useMemo } from "react";
import invariant from "tiny-invariant";
import type { USER_ROLES } from "~/components/users/form";
import { CurrentUser } from "~/contexts";
import type { CurrentUserFieldsFragment, User } from "~/types/api";

export type UserRoleType = (typeof USER_ROLES)[number] | RegExp;

export const useAllowed = (
  role: UserRoleType | UserRoleType[],
  user?: User
) => {
  const currentUser = useContext(CurrentUser);
  return useMemo(
    () => (currentUser ? isAllowed(role, currentUser, user) : false),
    [role, currentUser, user]
  );
};

export const isAllowed = (
  role: UserRoleType | UserRoleType[],
  currentUser: Pick<CurrentUserFieldsFragment, "roles">,
  user?: Pick<User, "roles">
) => {
  const roles = _.castArray(role);

  return roles.some((r) => {
    if (r instanceof RegExp) {
      return (user || currentUser).roles.some((role) => role.match(r));
    }
    return (user || currentUser).roles.includes(r);
  });
};

export const useCurrentUser = () => {
  const currentUser = useContext(CurrentUser);
  invariant(currentUser, "CurrentUser is required");
  return currentUser;
};
